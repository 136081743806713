@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

::-ms-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
